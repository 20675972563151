
<template>
  <div class="row gy-2">
    <div class="col-12">
      <div class="d-sm-flex mb-4 justify-content-between align-items-center">
        <h5 class="mb-3 mb-sm-0">Your stuff</h5>
      </div>
      <div v-if="!isLoading">
        <div v-if="items.length" class="row">
          <div class="col-sm-6" v-for="(item, dIndex) of items" :key="dIndex">
            <item-card :item="item">
              <template v-slot:actions>
                <router-link :to="`/items/${item.id}`" class="btn me-3 btn-primary btn-sm">
                View item</router-link>
              </template>
            </item-card>
          </div>
        </div>
        <div v-else class="fs-5 text-muted text-center">You haven't added any items to your account yet. <br> Click the "Add item" button above to add one</div>
      </div>
      <is-loading v-else />
    </div>
  </div>
</template>

<script>
import ItemCard from "@/components/cards/ItemCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components:{
    ItemCard,
    IsLoading
  },
  data(){
    return {
      isLoading: true
    }
  },
  computed:{
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    items(){
      return this.$store.state.items
    }
  },
  created(){
    this.$store.dispatch("fetchItems")
    .then((response) => { this.isLoading = false; });
  }
}

</script>

<style lang="scss">

</style>
