<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-start">
            <div class="me-2">
                <img v-if="item.device.image" style="width:3rem;" :src="absoluteUrl(item.device.image)" />
                <div v-else class="avatar img-thumbnail flex-shrink-0">
                    <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{item.device.model.charAt(0).toUpperCase()}}
                    </div>
                </div>
            </div> 
            <div class="">
                <h5 class="card-title mb-0">{{item.title}}</h5>
            </div>
        </div>
        <div class="row pt-3 g-2">
            <div class="col-6"> 
                <span>Item ID</span>
                <h6> {{item.friendly_id}} </h6>
            </div>
            <div class="col-6"> 
                <span>Status</span>
                <div><span v-html="statusBadge(item.status)"> </span></div>
            </div>
            <div class="col-sm-6"> 
                <span>Model Name</span>
                <h6> {{item.device.model}} </h6>
            </div> 
            <div v-if="item.serial_number" class="col-sm-6"> 
                <span>Serial Number</span>
                <h6> {{item.serial_number}} </h6>
            </div>
            <div v-if="item.imei_number" class="col-sm-6"> 
                <span>Imei Number</span>
                <h6> {{item.imei_number}} </h6>
            </div>
        </div>
        <router-link :to="`/items/${item.id}`" v-if="item.status == 'draft' || !item.purchase_price">
            <div class="bd-callout small py-2 mb-3 mt-0 bd-callout-warning">
                Update item as required in order to protect your item 
            </div>
        </router-link>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>

</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    },
    computed:{

    },    
}
</script>

<style>

</style>